
.average-card-container{
    border: solid;
    border-color: $light-gray;
    border-width: 0.25px;
    text-align: center;
    padding: 2%;
    width: 29%;
    display: flex;
    flex-direction: column;
}

.avarage-card-title{
    color:$dark-blue;
    font-weight: bold !important;
    font-size: 20px !important;
    margin-top: 10%;
    font-family: $calibri-light !important;
}

.average-card-value{
    font-size: 50px !important;
    color:$light-blue;
    font-family: $calibri-light !important;
}

.rolling-averages{
    display: flex;
    font-style: italic;
    color:$dark-blue;
    font-family: $calibri-light !important;
    justify-content: space-around;
}

.rolling-averages-title{
    font-style: italic;
    text-align: left;
    color: $dark-blue;
    font-family: $calibri-light;
    font-weight: bold;
    font-size: 15px;
}