.AppBar {
    background-color: #0a1f2e !important;
}

.Toolbar {
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
}

.ButtonLink {
    text-decoration: none;
}

.DrawerContent {
    width: 280px !important;
    height: 100%;
    background-color: #0a1f2e;
    word-break: keep-all;
}

.DrawerContent a {
    text-decoration: none !important;
    color: #ffffff;
}

.Paper {
    min-width: 150px;
}

.Paper a {
    text-decoration: none;
    color: inherit;
}

@media (max-width: 650px) {
    .ButtonLink {
        display: none;
    }
}

@media (min-width: 651px) {
    .HiddenLink {
        display: none;
    }
}

@media (max-width: 900px) {
    .Logo {
        display: none;
    }
}