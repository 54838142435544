.StickyHeader {
    position: sticky;
    top: 0px;
    width: 100%;
    z-index: 1;
}

@media (min-width: 600px) {
    .StickyHeader {
        top: 0px;
    }
}

.FixedHeader {
    margin-top: -10px;
}