.Paper {
    padding: 15px;
    max-width: 450px;
    margin: auto;
}

.Typography {
    padding: 0 10px;
}

.Divider {
    margin: 10px -15px !important;
}

.GridItem {
    padding: 20px 10px;
}

.GridButton {
    padding: 10px 10px 0 10px;
}