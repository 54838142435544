.fleet-container{
    min-height: 100vh;
    overflow: hidden;
}

.fleet-month-title{
    font-size: 39px i !important;
    font-family: $calibri-light !important; 
    color: $light-blue; 
    font-weight: 30px !important;
}

.currency-picker-dashboard{
    color: gray;
    font-size: 20px;
}

.view-expense-container{
    overflow: hidden;
}

.average-cards-div-container{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.expense-charts-container{
    margin-top: 3%;
    width:100%;
    display:flex;
}

.chart-border{
    border:solid;
    border-width:0.25px;
    border-color:$light-gray;
}

.left-chart{
    width:50%;
    border: none;
}

.right-chart{
    width:50%;
    border: none;
}

.selected-tab{
    font-weight: bold !important;
    color:$blue !important;
}

.unselected-tab{
    color:$blue !important;
}

.dashboard-tab-container{
    position: absolute;
    right: 45px;
    width: 100%;
}

.dashboard-filter-container{
    font-size:16;
    width:20%;
    position:relative;
    float:left;
    overflow: auto;
}

.dashboard-vendor-name{
    padding-left: 5px !important;
    font-weight: bold !important;
    font-family: $calibri-light !important;
    color: $light-blue;
}
