.Container {
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: #3e4f5c;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
}

.NewPasswordForm {
    max-width: 340px;
    padding: 30px 20px;
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0 0 200px rgba(255, 255, 255, 0.5), 0 1px 2px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    margin-top: 120px;
}

.Logo {
    text-align: center;
    padding: 5px 0 10px 0;
}

.InputText {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.Error {
    padding: 10px 0;
}

.Button {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}






/*


.Logo {
    padding: 15px 0;
    color: white !important;
}

.InputText {
    margin: 15px 5px;
    padding: 10px;
    border: 1px solid #7dc9e2;
    border-radius: 2px;
    outline: 5px solid #dceefc;
    color: #404040;
}

*/

.Error {
    margin: 0;
    color: red;
    text-align: center;
}

/*
.NewPasswordButton {
    margin: 15px 0;
    padding: 10px;
    border: 1px solid #7dc9e2;
    border-radius: 2px;
    outline: none;
    color: #404040;
    background-color: #e3f1f1;
}

.NewPasswordButton:hover {
    background-color: #cde5ef;
    cursor: pointer;
}

.Loading {
    color: #1010104d;
    background-color: #efefef;
    border-color: #1010104d;
}
  
.Loading:hover {
    cursor: wait;
    background-color: #efefef;
}

*/