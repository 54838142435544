.MainLayout {
    padding: 0px 10px 0px 10px;
    overflow-y: overlay;
    scrollbar-gutter: stable;
    height: calc(100vh - 79px);
}

@media (min-width: 600px) {
    .MainLayout {
        padding: 0px 15px 0px 10px;
        overflow-y: overlay;
        scrollbar-gutter: stable;
        height: calc(100vh - 79px);
    }
}