.Container {
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: #3e4f5c;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
}

.ResetForm {
    max-width: 340px;
    padding: 30px 20px;
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0 0 200px rgba(255, 255, 255, 0.5), 0 1px 2px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    margin-top: 120px;
}

.Logo {
    text-align: center;
    padding: 5px 0 10px 0;
}

.Button {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.Error {
    margin: 0;
    color: red;
    text-align: center;
}

.LoginLink {
    color: #37839a;;
    font-size: 16px;
    text-shadow: 1.2px 1.2px #3e4f5c;
    text-decoration: none;
}

.LoginLink:hover {
    text-decoration: underline;
}
