.Paper {
    padding: 15px;
    max-width: 1200px;
    margin: auto;
}

.Typography {
    padding: 0 10px;
}

.Divider {
    margin: 10px -15px !important;
}

/*.GridContainer {
    border: 1px solid black;
}*/

.GridItem {
    padding: 10px;
}

.GridButton {
    padding: 10px 10px 0 10px;
}