.Container {
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: #3e4f5c;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
}

.OptionsContainer {
    min-width: 340px;
    padding: 30px 20px;
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0 0 200px rgba(255, 255, 255, 0.5), 0 1px 2px rgba(0, 0, 0, 0.3);
    margin-top: 120px;
}

.LoginForm {
    display: flex;
    flex-direction: column;
}

.Logo {
    text-align: center;
    padding: 5px 0 10px 0;
}

.TextField {
    margin-top: 5px !important;
    margin-bottom: 10px !important;
    width: 100%;
}

.Button {
    margin-top: 10px !important;
    margin-bottom: 5px !important;
    width: 100%;
}

.ForgotPassword {
    color: #ffffff;
    font-size: 14px;
    padding-top: 20px;
}


.LoginFailed {
    color: #ff0000;
    text-align: center;
    font-size: 14px;
    padding-top: 20px;
}

.ResetLink {
    color: #cce7fa;
    text-shadow: 1.2px 1.2px #2a85a1;
    text-decoration: none;
}

.ResetLink:hover {
    text-decoration: underline;
}

@media (max-width: 550px) {
    .Container {
        background-color: #ffffff;
    }

    .LoginForm {
        box-shadow: none;
    }

    .ForgotPassword {
        color: #000000;
    }
}