.selected-charge{
    background-color: $light-blue !important;
    color: white !important;
    height:35px !important;
    font-size: 11px !important;
    font-weight:bold !important;
    border-radius: 0px !important;
}

.unselected-charge{
    height: 35px !important;
    font-size: 11px !important;
    font-weight: bold !important;
    border-radius: 0px !important;
}